<template>
  <v-select
    id="custom-filter"
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="options"
    item-value="value"
    item-text="text"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
export default {
  name: "CustomFilter",
  props: {
    value: Object,
    options: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    selectedValue: null
  }),

  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  }
};
</script>
