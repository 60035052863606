<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        {{ $i18n.translate("Participant") }} {{ $i18n.translate("Management") }}
        <v-chip v-if="organization" class="ma-2" close @click:close="clearOrganization()">
          {{
            organization.legacyOrganizationKey
              ? organization.legacyOrganizationKey + "&nbsp;-&nbsp;"
              : organization.organizationKey
              ? organization.organizationKey + "&nbsp;-&nbsp;"
              : ""
          }}
          {{ organization.name }}
        </v-chip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          dense
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Participants')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  v-model="filterByEffective"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ParticipantTypesField
                  dense
                  v-model="filterByParticipantTypes"
                  :label="$i18n.translate('Participant Types')"
                  @input="onSubmit"
                ></ParticipantTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <CustomFilter
                  :label="$i18n.translate('Participant') + ' Status'"
                  v-model="filterByParticipantStatus"
                  :options="participantStatusOption"
                  @input="onSubmit"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="hasFilters">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="mr-2" small fab v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
      <v-select
        class="show-columns"
        label="Show Columns"
        dense
        :items="showHeaders"
        v-model="selectedHeaders"
        clearable
        multiple
        hide-details
        flat
        solo-inverted
        return-object
        @change="initHeaders"
      >
        <template v-slot:selection="data">{{ showSelected(data) }}</template>
      </v-select>
    </v-toolbar>
    <v-card-text>
      <v-card v-if="organization" dark>
        <v-card-title>
          <span>
            {{
              organization.legacyOrganizationKey
                ? organization.legacyOrganizationKey + "&nbsp;-&nbsp;"
                : organization.organizationKey
                ? organization.organizationKey + "&nbsp;-&nbsp;"
                : ""
            }}
          </span>
          <span>{{ organization.name }}</span>
        </v-card-title>
        <v-card-text>
          <span v-if="organizationType">
            {{ organizationType.name }}
            {{
              organization.legacyOrganizationKey
                ? organization.legacyOrganizationKey
                : organization.organizationKey
                ? organization.organizationKey
                : ""
            }}
            <span v-if="parentOrganizations">
              <span v-for="parentOrganization in parentOrganizations" :key="parentOrganization.id">
                <v-icon small>mdi-arrow-right</v-icon> {{ parentOrganization.name }}
              </span>
            </span>
          </span>
          <div v-if="organization.address">{{ organization.address.fullAddress }}</div>
          <div v-if="organization.phoneNumber1">{{ organization.phoneNumber1 | toPhoneNumber }}</div>
          <div v-if="total != null">{{ total }} {{ total == 1 ? "person" : "people" }}</div>
        </v-card-text>
      </v-card>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        mobile-breakpoint="800"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.organization="{ item }">
          <span v-if="item.organization">
            {{
              item.organization
                ? item.organization.legacyOrganizationKey
                  ? item.organization.legacyOrganizationKey + " - "
                  : item.organization.organizationKey + " - "
                : ""
            }}
            {{ item.organization.name }}
            <div v-if="!loading && getRelatedOrganizations(item.id) != null">
              <span v-for="relatedOrganization in getRelatedOrganizations(item.id)" :key="relatedOrganization.id">
                {{
                  relatedOrganization.relatedOrganization.legacyOrganizationKey
                    ? relatedOrganization.relatedOrganization.legacyOrganizationKey + " - "
                    : relatedOrganization.relatedOrganization.organizationKey + " - "
                }}
                {{ relatedOrganization.relatedOrganization.name }} <br />
              </span>
            </div>
          </span>
        </template>
        <template v-slot:item.user.username="{ item }">
          <div>
            <div>{{ item.user.username }}</div>
            <div v-if="item.user.legacyUsername" class="legacy-username">
              {{ item.user.legacyUsername }}
            </div>
          </div>
        </template>
        <template v-slot:item.user.lastSuccessfulLoginDate="{ item }">
          {{ item.user.lastSuccessfulLoginDate | formatDateFromNow }}
        </template>
        <template v-slot:item.canImpersonate="{ item }">
          <v-icon v-if="hasImpersonatePrivilege(item.user)" color="green">mdi-check</v-icon>
          <v-icon v-else color="red">mdi-close</v-icon>
        </template>
        <template
          v-for="index in selectedProgram.participantCustomFields.length"
          v-slot:[getCustomFieldSlotName(index)]="{ item }"
        >
          <span :key="index">
            {{
              $customField.renderValue(
                selectedProgram.participantCustomFields[index - 1],
                item["customFieldValue" + index]
              )
            }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-container>
            <v-tooltip bottom v-if="cantImpersonateReason(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" fab small color="warning" v-bind="attrs" v-on="on">
                  <v-icon>mdi-account-switch-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ cantImpersonateReason(item) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" fab small @click="onImpersonate(item)" v-bind="attrs" v-on="on">
                  <v-icon>mdi-account-switch-outline</v-icon>
                </v-btn>
              </template>
              <span>Impersonate</span>
            </v-tooltip>
          </v-container>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style>
.show-columns {
  max-width: 20%;
}
</style>

<script>
import { mapGetters } from "vuex";
import ParticipantTypesField from "../../gapp-components/components/fields/ParticipantTypesField.vue";
import CustomFilter from "@/gapp-components/components/fields/CustomFilter.vue";

export default {
  components: { ParticipantTypesField, CustomFilter },
  name: "ParticipantManagement",
  metaInfo: {
    title: "Salesperson Management"
  },
  data() {
    return {
      search: "",
      filterByParticipantTypes: [],
      filterByParticipantStatus: { value: "ENR" },

      loading: false,
      items: [],
      nameFilter: "",
      headers: [],
      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1
      },
      organization: null,
      organizationType: null,
      parentOrganizations: null,
      filterByEffective: "Active",
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      showHeaders: [],
      selectedHeaders: [],
      firstFetch: false,
      relatedOrganizations: null
    };
  },
  watch: {
    options: {
      handler() {
        if (this.firstFetch) {
          this.fetchData();
        }
      }
    }
  },
  methods: {
    showSelected(v) {
      if (v.index == 0) {
        return this.selectedHeaders.length + " Selected";
      }
      return "";
    },

    initShowHeaders() {
      this.showHeaders = [];

      let size = this.selectedProgram.participantCustomFields.length;
      for (let i = 1; i <= size; i++) {
        let participantCustomField = this.selectedProgram.participantCustomFields[i - 1];
        if (participantCustomField.name) {
          this.showHeaders.push({
            value: "customFieldValue" + i,
            text: this.$i18n.translate(participantCustomField.name),
            sortable: true,
            align: this.$customField.alignValue(participantCustomField)
          });
        }
      }
    },
    initHeaders() {
      this.headers = [];
      this.headers.push({
        value: "participantKey",
        text: this.$i18n.translate("Key"),
        sortable: true
      });
      this.headers.push({
        value: "user.fullName",
        text: this.$i18n.translate("Name"),
        sortable: true
      });
      this.headers.push({
        value: "user.username",
        text: this.$i18n.translate("Username"),
        sortable: false
      });
      this.headers.push({
        value: "organization",
        text: this.$i18n.translate("Organization"),
        sortable: true
      });
      this.headers.push({
        value: "participantType.name",
        text: this.$i18n.translate("Type"),
        align: "end",
        sortable: false
      });
      this.headers.push({
        value: "status.description",
        text: this.$i18n.translate("Status"),
        align: "end",
        sortable: true
      });
      this.headers.push({
        value: "user.lastSuccessfulLoginDate",
        text: this.$i18n.translate("Last Login"),
        align: "end",
        sortable: true
      });
      if (this.$privilege.hasPrivilege("PARTICIPANT_IMPERSONATE")) {
        this.headers.push({
          value: "canImpersonate",
          text: this.$i18n.translate("Impersonate Privilege"),
          align: "center",
          sortable: false
        });
      }

      let size = this.selectedHeaders.length;
      for (let i = 0; i < size; i++) {
        this.headers.push(this.selectedHeaders[i]);
      }

      if (this.$privilege.hasPrivilege("PARTICIPANT_IMPERSONATE")) {
        this.headers.push({
          value: "actions",
          text: this.$i18n.translate("Actions"),
          align: "center",
          sortable: false
        });
      }
    },

    getCustomFieldSlotName(index) {
      let fieldValue = "item.customFieldValue" + index;
      return fieldValue;
    },
    onClear() {
      this.search = "";
      this.fetchData();
    },
    onSubmit() {
      this.options.page = 1;
      this.fetchData();
    },
    onClearSearch() {
      this.search = "";
      this.filterByEffective = undefined;
      this.filterByParticipantTypes = [];
      this.onSubmit();
    },
    clearOrganization() {
      this.$router.push({ name: "companyManagement" });
    },
    fetchData() {
      this.loading = true;
      if (this.$route.query.organizationId) {
        if (this.organization) {
          return Promise.all([
            this.fetchOrganizationType(),
            this.fetchParticipants(this.organization).then(() => {
              return this.fetchRelatedOrganizations();
            }),
            this.fetchParentOrganizations()
          ]).finally(() => {
            this.loading = false;
            this.firstFetch = true;
          });
        } else {
          return this.$api.get("/api/organizations/" + this.$route.query.organizationId).then(({ data }) => {
            this.organization = data;
            return Promise.all([
              this.fetchOrganizationType(),
              this.fetchParticipants(this.organization).then(() => {
                return this.fetchRelatedOrganizations();
              }),
              this.fetchParentOrganizations()
            ]).finally(() => {
              this.loading = false;
              this.firstFetch = true;
            });
          });
        }
      } else {
        return this.fetchParticipants()
          .then(() => {
            return this.fetchRelatedOrganizations();
          })
          .finally(() => {
            this.loading = false;
            this.firstFetch = true;
          });
      }
    },

    fetchOrganizationType() {
      return this.$api
        .get("/api/organizations/" + this.organization.id + "/organizationType")
        .then(({ data }) => {
          this.organizationType = data;
        })
        .catch(() => {
          console.log("No organization type found");
        });
    },

    fetchParentOrganization(organization) {
      return this.$api
        .get("/api/organizations/" + organization.id + "/parentOrganization")
        .then(({ data }) => data)
        .catch(() => {
          console.log("No parent organization found");
        });
    },

    async fetchParentOrganizations() {
      let parents = [];
      if (this.organization && this.organization.id) {
        let parentOrganization1 = await this.fetchParentOrganization(this.organization);
        if (parentOrganization1) {
          parents.push(parentOrganization1);
          let parentOrganization2 = await this.fetchParentOrganization(parentOrganization1);
          if (parentOrganization2) {
            parents.push(parentOrganization2);
            let parentOrganization3 = await this.fetchParentOrganization(parentOrganization2);
            if (parentOrganization3) {
              parents.push(parentOrganization3);
              let parentOrganization4 = await this.fetchParentOrganization(parentOrganization3);
              if (parentOrganization4) {
                parents.push(parentOrganization4);
              }
            }
          }
        }
      }

      this.parentOrganizations = parents;
      return Promise.resolve();
    },

    fetchParticipantType(key) {
      return this.$api.post("/api/participantTypes/search?size=1", { participantTypeKey: key }).then(({ data }) => {
        return data && data.content.length == 1 ? data.content[0] : null;
      });
    },

    fetchRelatedOrganizations() {
      this.relatedOrganizations = new Map();
      let promises = [];
      for (let i = 0; i < this.items.length; i++) {
        promises.push(this.fetchRelatedOrganizationsForParticipant(this.items[i]));
      }
      return Promise.all(promises).then(() => {
        this.$forceUpdate();
      });
    },

    fetchRelatedOrganizationsForParticipant(participant) {
      return this.$api
        .post("/api/relatedOrganizations/search?size=25&sort=relatedOrganization.organizationKey", {
          participant: { id: participant.id }
        })
        .then(({ data }) => {
          this.relatedOrganizations.set(participant.id, data.content);
        });
    },

    getRelatedOrganizations(id) {
      return this.relatedOrganizations != null ? this.relatedOrganizations.get(id) : null;
    },

    fetchParticipants(org) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      filters.isEffective = this.filterByEffective;

      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      let isCurrentFiscalYear = this.selectedFiscalYear.fiscalYear == currentFiscalYear;

      if (!isCurrentFiscalYear && this.$route.query.participantTypeKey != undefined) {
        filters.enrolledAfter = this.selectedFiscalYear.fiscalYearStart;
        filters.enrolledBefore = this.selectedFiscalYear.fiscalYearEnd;
      }

      if (this.filterByParticipantTypes && this.filterByParticipantTypes.length > 0) {
        filters.participantTypes = this.filterByParticipantTypes.map(participantType => {
          return {
            id: participantType.id
          };
        });
      }

      if (this.filterByParticipantStatus) {
        filters.status = { name: this.filterByParticipantStatus.value };
      }

      if (org) {
        filters.organization = { id: org.id };
      }
      let sortByName = "sort";
      if (sortBy && sortBy.length > 0 && sortBy[0].includes(".")) {
        sortByName = "nestedSort";
      }

      return this.$api
        .post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&" +
                sortByName +
                "=" +
                sortBy[0] +
                "," +
                ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        });
    },
    hasImpersonatePrivilege(user) {
      return this.$privilege.hasImpersonatePrivilegeForUser(user);
    },
    cantImpersonateReason(participant) {
      if (this.selectedParticipant && this.selectedParticipant.id == participant.id) {
        return "Can't impersonate yourself";
      }
      if (this.impersonationParticipant && this.impersonationParticipant.id == participant.id) {
        return "Already impersonating user";
      }
      if (!this.$privilege.hasImpersonatePrivilege()) {
        return "Don't have impersonating privilege";
      }
      if (this.$privilege.hasImpersonatePrivilegeForUser(participant.user)) {
        return "Can't impersonate one who has impersonate privilege";
      }
      // Allowed to impersonate
      return undefined;
    },
    onImpersonate(participant) {
      this.isBusy = true;
      this.$api.get("/api/participants/byParticipantId/" + participant.id).then(({ data }) => {
        this.$auth.startImpersonation(data).then(() => {
          this.isBusy = false;
          this.$router.push({ name: "dashboard" }).catch(() => {});
        });
      });
    }
  },
  created() {
    if (this.$route.query.participantTypeKey != undefined) {
      this.fetchParticipantType(this.$route.query.participantTypeKey).then(participantType => {
        if (participantType) {
          this.filterByParticipantTypes.push(participantType);
        }
        this.onSubmit();
      });
    } else {
      this.onSubmit();
    }
  },
  mounted() {
    this.initShowHeaders();
    this.initHeaders();

    if (this.$route.query.active != undefined) {
      this.filterByEffective = parseInt(this.$route.query.active) == 1;
    }

    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Admin"
      },
      { text: "Participant Management" }
    ]);
  },
  computed: {
    ...mapGetters([
      "impersonationParticipant",
      "selectedParticipant",
      "selectedProgram",
      "selectedClient",
      "selectedFiscalYear"
    ]),
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        this.filterByEffective != undefined ||
        (this.filterByParticipantTypes && this.filterByParticipantTypes.length > 0);
      return check;
    },
    participantStatusOption() {
      return [
        {
          value: "PRE",
          text: "Pre-Enrolled"
        },
        {
          value: "ENR",
          text: "Enrolled"
        },
        {
          value: "CAN",
          text: "Cancelled"
        },
        {
          value: "PRE_CAN",
          text: "Pre-Cancelled"
        }
      ];
    }
  }
};
</script>

<style>
.legacy-username {
  font-size: 1em;
  color: gray;
}
</style>
